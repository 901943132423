module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.mlpost.local/graphql","verbose":true,"debug":{"preview":true,"timeBuildSteps":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"schema":{"perPage":1000,"requestConcurrency":200,"timeout":1200000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"type":{"Tag":{"limit":1},"Post":{"limit":1000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"News Post System","short_name":"NPS","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/nps-icon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e99d2631bdd3372a563c34f6440bd60"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
